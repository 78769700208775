@font-face {
    font-family: 'Helvetica-Extra-Light';
    src: local('Helvetica-Extra-Light'), url(../fonts/HelveticaNowDisplay-ExtLt.woff) format('woff');
  }

@font-face {
    font-family: 'Helvetica-Light';
    src: local('Helvetica-Extra-Light'), url(../fonts/HelveticaNowDisplay-Light.woff) format('woff');
  }

@font-face {
    font-family: 'Helvetica-Light-Italic';
    src: local('Helvetica-Light-Italic'), url(../fonts/HelveticaNowDisplay-LightIta.woff) format('woff');
  }

  @font-face {
    font-family: 'Helvetica-Regular';
    src: local('Helvetica-Regular'), url(../fonts/HelveticaNowDisplay-Regular.woff) format('woff');
  }

  @font-face {
    font-family: 'Helvetica-Regular-Italic';
    src: local('Helvetica-Regular-Italic'), url(../fonts/HelveticaNowDisplay-RegIta.woff) format('woff');
  }

  @font-face {
    font-family: 'Helvetica-Medium';
    src: local('Helvetica-Medium'), url(../fonts/HelveticaNowDisplay-Medium.woff) format('woff');
  }

  @font-face {
    font-family: 'Helvetica-Medium-Italic';
    src: local('Helvetica-Medium-Italic'), url(../fonts/HelveticaNowDisplay-MedIta.woff) format('woff');
  }

  @font-face {
    font-family: 'Helvetica-Bold';
    src: local('Helvetica-Bold'), url(../fonts/HelveticaNowDisplay-Bold.woff) format('woff');
  }

  @font-face {
    font-family: 'Helvetica-Bold-Italic';
    src: local('Helvetica-Extra-Light'), url(../fonts/HelveticaNowDisplay-BoldIta.woff) format('woff');
  }

  @font-face {
    font-family: 'Helvetica-ExtraBold';
    src: local('Helvetica-ExtraBold'), url(../fonts/HelveticaNowDisplay-ExtraBold.woff) format('woff');
  }

  @font-face {
    font-family: 'Helvetica-ExtraBold-Italic';
    src: local('Helvetica-ExtraBold-Italic'), url(../fonts/HelveticaNowDisplay-ExtBdIta.woff) format('woff');
  }

  @font-face {
    font-family: 'Helvetica-Black-Italic';
    src: local('Helvetica-Black-Italic'), url(../fonts/HelveticaNowDisplay-BlackIta.woff) format('woff');
  }

  @font-face {
    font-family: 'Helvetica-ExtraBlack';
    src: local('Helvetica-ExtraBlack'), url(../fonts/HelveticaNowDisplay-ExtBlk.woff) format('woff');
  }

  @font-face {
    font-family: 'Helvetica-ExtraBlack-Italic';
    src: local('Helvetica-ExtraBlack-Italic'), url(../fonts/HelveticaNowDisplay-ExtBlkIta.woff) format('woff');
  }

html{
  scroll-behavior: smooth;
}
  /* width */
::-webkit-scrollbar {
  width: 10px;
}

:root{
  --toastify-color-progress-light:#6c757d !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #343a40; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6c757d;
}

a{
  text-decoration: none;
}

.parallax{
  width: 200% !important;
  transform-style: preserve-3d !important;
}

.college{
  margin-bottom:2rem !important ;
}

.scrollAnim{
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  opacity: .4;
  width: 35px;
  @media (max-width: 1020px) {
    display: none;
  }
}

.otherlinks span{
  color: gray;
  font-size:small;
  margin:0 5px;
}

.modal-card{
  overflow-y: scroll;
  height: 70vh;
  margin-top: 1.5rem;
  padding-right: 1rem;
  width: 100%;
}

.modal-card ul{
  margin-bottom: 1rem;
  margin-top: .5rem;
}

.modal-card .modal-img{
  width: 100%;
  margin-bottom: 1rem;
}

.customModal h1{
  font-size: 1.5rem;
  text-align: center;
}

.card-stack-box{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.modal-card p{
  font-size: 1.2rem;
  margin-bottom: 2rem;
  font-weight: 500;
  color:#6c757d;
}

.customOverlay {
  background: #343a40b7;
}
.customModal {
  background: #f8f9fa;
  border-radius: 7px;
}

.demo-link{
  color: #343a40;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-linked{
  z-index: 11000;
}